<template>
  <AppCcc 
    v-if="content && content.length > 0"
    :content="content"
    :scene-data="sceneData"
    :context="cccAppContext"
    :cate-links="cateLinks"
  />
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import AppCcc from 'public/src/pages/components/ccc/Index.vue'

export default {
  name: 'CccxComp',
  components: {
    AppCcc
  },
  props: {
    cccxContext: {
      type: Object,
      default: () => ({}),
    },
    content: {
      type: Array,
      default: () => [],
    }
  },
  computed: {
    ...mapState([
      'PICKS_LINKS',
    ]),
    ...mapGetters([
      'locals',
    ]),
    sceneData() {
      const { pageType = '', id = '' } = this.cccxContext || {}
      return {
        sceneName: pageType, // 埋点需要
        pageType: pageType, // 埋点需要
        pageFrom: pageType, // 标识应用在哪个页面，你也可以直接取ccc后台返的pageType
        id,
      }
    },
    cateLinks() {
      return this.cccxContext?.cateLinks || {}
    },
    cccAppContext() {
      const { WEB_CLIENT } = this.locals || {}
      return {
        WEB_CLIENT,
        PICKS_GLOBAL_INFO: {
          LINKS: this.PICKS_LINKS,
        }
      }
    },
  },
}
</script>
